.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000; /* Or any color that fits your design */
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #fff; /* Or any color that fits your design */
    border-radius: 50%;
    margin: 0 5px;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0s;
}

.dot2 {
    animation-delay: 0.2s;
}

.dot3 {
    animation-delay: 0.4s;
}

@keyframes dotFlashing {
    0% {
        background-color: #fff; /* Starting color */
        transform: scale(1);
    }
    50%,
    100% {
        background-color: #000; /* Or any contrasting color */
        transform: scale(1.5);
    }
}

.home:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url('home_background.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

.home-name {
    position: relative;
    color: lightcoral;
    font-size: 50px;
}

.horizontal-line {
    position: relative;
    border-top: 5px solid white;
    width: 450px; 
    margin-top: 25px;
    margin-bottom: 25px;
}

.pink-navbar {
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
}

.pink-navbar-link {
    padding: 20px;
    font-size: 20px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    z-index: 1;
    font-weight: bolder;
}

.pink-navbar-link:hover {
    color: white;
    font-weight: bolder;
}

.footer {
    position: relative;
}

html {
    scroll-behavior: smooth;
}
