.about {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.5vw;
}

.about-bio {
    margin-left: 50px;
}

.about-link {
    display: flex;
    font-weight: bolder;
    text-decoration: none;
    color: rgb(12, 230, 12);
    display: inline;
}

.about-link:hover {
    color: darkgreen;
}

.about-footer:hover {
    color:lightcoral;
}

.hillary-quote {
    font-family: Georgia, 'Times New Roman', Times, serif;
}