.projects-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(207, 237, 207);
}

.projects {
    color: black;
    padding: 20px 140px 0px 140px;
    display: flex;
    justify-content: center;
    max-width: 100vw;
}

.projects-header {
    color: white;
}

.project-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 30vw;
    margin: 1vw;
}

.project-image-wrap {
    position: relative;
    height: 30vh;
    width: 30vw;
}

.project-description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:rgb(237, 171, 171);
    text-align: center;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    padding: 10px;
    font-weight: bolder;
    transition: opacity .2s, visibility .2s;
}

.project-image-wrap:hover .project-description {
    visibility: visible;
    opacity: 0.9;
}

.project-image {
    opacity: 0.6;
    height: 30vh;
    width: 30vw;
}

.project-link {
    text-decoration: none;
}

.project-name {
    color: lightcoral;
    font-weight: bolder;
}

.project-name:hover {
    color: white;
}