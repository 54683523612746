.footer {
    height:75px; 
    width: 100%; 
    /* background-image: none;
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0% 0%; */
    position: fixed;
    bottom: 0pt;
    left: 0pt;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.footer-contents {
    height:75px; 
    width: 100vw; 
    margin:auto;
    display: flex;
    justify-content: center;
}

.icon {
    border-radius: 50%;
    padding: 10px;
    font-size: 30px;
    background-color: lightcoral;
    margin: 10px;
    color: white;
    z-index: 1;
    border: 3px solid lightcoral;
}

.icon:hover {
    background-color: transparent;
}